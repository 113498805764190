import React from 'react';

import { Box, Button, Icon, Theme } from '@mui/material';

import { usePage, useTheme, useFormData, useProtocol } from 'hooks';

const useStyles = (theme: Theme): any => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'inherit',
    },
  },
  button: {
    width: '110px',
  },
});

const NavigationButton: React.FC<{ pastFormRequest?: boolean }> = ({
  pastFormRequest,
}) => {
  const { nextPage, previousPage } = usePage();
  const { systemTheme } = useTheme();
  const { fetchPreviousForm } = useFormData();

  const classes = useStyles(systemTheme);
  const nrProtocol = useProtocol();

  const pastDsSearch = (): void => {
    fetchPreviousForm(nrProtocol);
  };

  return (
    <Box sx={classes.root}>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<Icon>arrow_back_ios_icon</Icon>}
        onClick={previousPage}
        sx={classes.button}
      >
        Voltar
      </Button>
      <Button
        variant="contained"
        color="primary"
        endIcon={<Icon>arrow_forward_ios_icon</Icon>}
        onClick={pastFormRequest ? pastDsSearch : nextPage}
        sx={classes.button}
      >
        Avançar
      </Button>
    </Box>
  );
};

export default NavigationButton;
