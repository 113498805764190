import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';

import { get } from 'lodash';

type QuestionButtonsProps = {
  name: string;
  disable?: boolean;
};

const useStyles = (): any => ({
  checkbox: {
    alignSelf: 'end',
  },
  label: {
    marginRight: 0,
  },
});

const QuestionButtons: React.FC<QuestionButtonsProps> = ({ name, disable }) => {
  const classes = useStyles();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, formState: { errors } }) => (
        <FormControl error={!!get(errors, name)?.message}>
          <RadioGroup {...field} sx={classes.checkbox}>
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Sim"
              sx={classes.label}
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Não"
              sx={classes.label}
              disabled={disable}
            />
          </RadioGroup>
          <FormHelperText>{get(errors, name)?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default QuestionButtons;
