import React, { LegacyRef } from 'react';
import InputMask from 'react-input-mask';

import { Input, TextField, useMediaQuery } from '@mui/material';

import { useTheme } from 'hooks';

const useStyles = (): any => ({
  root: {
    maxWidth: 200,
    width: '100%',
    '& > .MuiFormHelperText-root': {
      textAlign: 'center',
    },
  },
});

export interface IFormValues {
  cpf: string;
}

type InputProps = {
  onBlur: () => void;
  onChange: (value: unknown) => void;
  inputRef: LegacyRef<InputMask>;
  errorMessage: string | undefined;
};

const CpfInput: React.FC<InputProps> = ({
  onBlur,
  onChange,
  inputRef,
  errorMessage,
}) => {
  const classes = useStyles();
  const matchesXs = '(min-width: 465px)';

  return (
    <InputMask
      ref={inputRef}
      onBlur={onBlur}
      onChange={onChange}
      mask="9999"
      maskChar={null}
      alwaysShowMask
    >
      {() => (
        <TextField
          autoFocus
          error={!!errorMessage}
          helperText={errorMessage}
          inputProps={{
            style: {
              textAlign: 'center',
              fontSize: matchesXs ? '50px' : '35px',
            },
          }}
          sx={classes.root}
          variant="standard"
        />
      )}
    </InputMask>
  );
};

export default CpfInput;
