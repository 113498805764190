type ExtensionType = 'pdf' | 'jpg' | 'jpeg' | 'png';

const base64ToArrayBuffer = (base64: string): Uint8Array => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);

  for (let i = 0; i < binaryLen; i += 1) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }

  return bytes;
};

const openPdfFromBase64 = (base64: string): void => {
  const arrayBuffer = base64ToArrayBuffer(base64);
  const file = new Blob([arrayBuffer], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);

  window.open(fileURL);
};

const openFile = (file: File): void => {
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};

const downloadPdfFromBase64 = (base64: string, filename: string): void => {
  const a = document.createElement('a');
  const linkSource = `data:application/pdf;base64,${base64}`;

  a.href = linkSource;
  a.download = filename;
  a.click();
};

const getFileFromBase64 = (
  base64: string,
  fileName: string,
  mimeType: string,
): File => {
  const arrayBuffer = base64ToArrayBuffer(base64);
  return new File([arrayBuffer], fileName, { type: mimeType });
};

const getMimeTypeFromExtension = (extension: ExtensionType): string => {
  const mimeTypes = {
    pdf: 'application/pdf',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
  };

  return mimeTypes[extension];
};

const checkMaxFileSize = (file: File, maxSize: number): boolean => {
  const size = file.size / 1024 / 1024;
  return size < maxSize;
};

const checkIfFilesAreCorrectType = (
  file: File,
  extensions: ExtensionType[],
): boolean => {
  if (!file) {
    return false;
  }

  const mimeTypes = extensions.map(extension =>
    getMimeTypeFromExtension(extension),
  );

  return mimeTypes.includes(file.type);
};

export default {
  base64ToArrayBuffer,
  openPdfFromBase64,
  downloadPdfFromBase64,
  getFileFromBase64,
  openFile,
  getMimeTypeFromExtension,
  checkMaxFileSize,
  checkIfFilesAreCorrectType,
};
