import React, { createContext, useEffect, useState } from 'react';

import { IPageContext } from '../interfaces';

const PageContext = createContext<IPageContext>({} as IPageContext);

const PageProvider: React.FC = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [step, setStep] = useState(0);

  const previousPage = (): void => {
    setCurrentPage(prevState => prevState - 1);
  };

  const nextPage = (): void => {
    setCurrentPage(prevState => prevState + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  return (
    <PageContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        step,
        setStep,
        previousPage,
        nextPage,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export { PageContext, PageProvider };
