import React from 'react';

import { Box, Paper, Theme, Typography, useMediaQuery } from '@mui/material';

import { NavigationButton } from 'components';

import { useFormData, useTheme } from 'hooks';

import { mask } from 'utils';

const useStyles = (theme: Theme): any => ({
  page: { paddingTop: 5 },
  wrapper: {
    marginTop: 5,
    display: 'grid',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    rowGap: 5,
  },
  wrapperXs: {
    marginTop: '35px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 5,
    gap: 5,
  },
  card: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr',
    padding: 3,
    width: '95%',
    maxWidth: 790,
    [theme.breakpoints.down(1050)]: {
      padding: 2.5,
      width: '90%',
    },
  },
  cardSm: {
    display: 'block',
    justifyContent: 'space-between',
    padding: 3,
    width: '90%',
    [theme.breakpoints.down(465)]: {
      padding: 2,
      width: '90%',
    },
  },
  boxSm: {
    marginBottom: 1.5,
  },
  title: { fontWeight: 'bold' },
  titleXs: {
    marginTop: '35px',
    fontSize: '1.7rem',
  },
  pageFooter: {
    maxWidth: 650,
    width: '100%',
    margin: '30px auto 0',
    paddingBottom: '10px',
  },
});

const InfosImportantes: React.FC = () => {
  const { beneficiariosConfig } = useFormData();
  const { systemTheme } = useTheme();

  const classes = useStyles(systemTheme);
  const matchesXs = useMediaQuery('(min-width: 465px)');
  const matchesSm = useMediaQuery('(min-width: 725px)');

  return (
    <Box sx={classes.page}>
      <Typography
        variant="h3"
        align="center"
        sx={matchesXs ? undefined : classes.titleXs}
      >
        Informações Importantes
      </Typography>
      <Box sx={matchesXs ? classes.wrapper : classes.wrapperXs}>
        {beneficiariosConfig.map(item => (
          <Paper
            elevation={5}
            sx={matchesSm ? classes.card : classes.cardSm}
            key={item.idBeneficiario}
          >
            <Box sx={matchesSm ? undefined : classes.boxSm}>
              <Typography sx={classes.title}>Beneficiário</Typography>
              <Typography gutterBottom>{item.nome}</Typography>
            </Box>
            <Box sx={matchesSm ? undefined : classes.boxSm}>
              <Typography sx={classes.title}>Contato</Typography>
              {item.telefone ? (
                <Typography gutterBottom>
                  {mask.formatTelefone(item.telefone)}
                </Typography>
              ) : (
                <Typography gutterBottom>Não informado</Typography>
              )}
            </Box>
            <Box sx={matchesSm ? undefined : classes.boxSm}>
              <Typography sx={classes.title}>Data de nascimento</Typography>
              <Typography gutterBottom>{item.dataNasc}</Typography>
            </Box>
          </Paper>
        ))}
      </Box>

      <Box sx={classes.pageFooter}>
        <NavigationButton pastFormRequest />
      </Box>
    </Box>
  );
};

export default InfosImportantes;
