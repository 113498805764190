import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TextField } from '@mui/material';

import { get } from 'lodash';

interface ITextInputDisease {
  name: string;
  label: string;
  maxLength: number;
  focused?: boolean;
  disable?: boolean;
}

const useStyles = (): any => ({
  input: {
    '& > label': {
      position: 'relative',
      display: 'block',
      textWrap: 'wrap',
    },
    '& > .MuiInputBase-root': { marginTop: 0 },
    width: '100%',
  },
});

const TextInputDisease: React.FC<ITextInputDisease> = ({
  name,
  label,
  maxLength,
  focused = false,
  disable,
}) => {
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onBlur, onChange, ref, value },
        formState: { errors },
      }) => (
        <TextField
          autoFocus={focused}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          inputRef={ref}
          sx={classes.input}
          variant="standard"
          size="small"
          label={label}
          margin="dense"
          error={!!get(errors, name)?.message}
          helperText={`${value.length}/${maxLength}`}
          inputProps={{ maxLength }}
          disabled={disable}
        />
      )}
    />
  );
};

export default TextInputDisease;
