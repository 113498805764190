import { useContext } from 'react';
import { FormContext, IQuestionario } from 'context';

interface UseQuestionType {
  questionario: IQuestionario[];
}

const useQuestion = (): UseQuestionType => {
  const { formData } = useContext(FormContext);
  const { questionario } = formData;

  return { questionario };
};

export default useQuestion;
