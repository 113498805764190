import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Collapse,
  FormGroup,
  Grid,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { CheckboxDisease, QuestionButtons, TextInputDisease } from 'components';

import { IQuestionario } from 'context';

import { useTheme } from 'hooks';

import {
  MAX_LENGTH_INPUT_TEXT,
  MAX_LENGTH_INPUT_TEXT_TEMPO,
} from 'pages/Questoes';

type BeneficiarioProps = {
  nome: string;
  idBeneficiario: string;
};

type QuestionAccordionProps = {
  beneficiario: BeneficiarioProps;
  disease: IQuestionario;
  validation?: boolean;
};

const useStyles = (theme: Theme): any => ({
  root: {
    padding: '1.5rem',
    width: '100%',
  },
  rootXs: {
    padding: '0.7rem',
    width: '94%',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '6fr 1fr',
    [theme.breakpoints.down(600)]: {
      gridTemplateColumns: '3fr 1fr',
    },
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    fontSize: '1.5rem',
  },
  nameXs: {
    fontSize: '1rem',
  },
});

const QuestionAccordion: React.FC<QuestionAccordionProps> = ({
  beneficiario,
  disease,
  validation,
}) => {
  const { systemTheme } = useTheme();
  const classes = useStyles(systemTheme);

  const matches = useMediaQuery('(min-width: 695px)');
  const matchesXs = useMediaQuery('(min-width: 465px)');

  const inputName = beneficiario.idBeneficiario;
  const { watch, setValue } = useFormContext();
  const watchHasDisease = watch(`${inputName}.hasDisease`) === 'true';

  const especificacaoDinamica = useMemo(
    () => (disease.especificacaoDinamica ? disease.especificacao[0] : null),
    [disease],
  );

  const especificacaoManual = useMemo(
    () => disease.especificacaoManual,
    [disease],
  );

  const especificacaoManualAlteracoes = useMemo(
    () => disease.especificacaoManualAlteracoes,
    [disease],
  );

  useEffect(() => {
    if (watchHasDisease && especificacaoDinamica) {
      setValue(`${inputName}.especificacao[0].portador`, true);
    }
    if (!watchHasDisease && especificacaoDinamica) {
      setValue(`${inputName}.especificacao[0].portador`, false);
    }
  }, [watchHasDisease, especificacaoDinamica]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Paper elevation={5} sx={matches ? classes.root : classes.rootXs}>
        <Grid sx={classes.grid}>
          <Typography sx={matchesXs ? classes.name : classes.nameXs}>
            {beneficiario.nome}
          </Typography>
          <QuestionButtons
            name={`${inputName}.hasDisease`}
            disable={validation}
          />
        </Grid>

        <Collapse in={watchHasDisease} timeout="auto">
          {watchHasDisease && especificacaoDinamica?.titulo && (
            <FormGroup>
              <TextInputDisease
                name={`${inputName}.especificacao[0].titulo`}
                label={especificacaoDinamica.titulo}
                maxLength={MAX_LENGTH_INPUT_TEXT}
                disable={validation}
                focused
              />
            </FormGroup>
          )}

          {watchHasDisease && especificacaoDinamica?.tempo && (
            <FormGroup>
              <TextInputDisease
                name={`${inputName}.especificacao[0].tempo`}
                label={especificacaoDinamica.tempo}
                maxLength={MAX_LENGTH_INPUT_TEXT_TEMPO}
                disable={validation}
              />
            </FormGroup>
          )}

          {watchHasDisease && especificacaoDinamica?.tipo && (
            <FormGroup>
              <TextInputDisease
                name={`${inputName}.especificacao[0].tipo`}
                label={especificacaoDinamica.tipo}
                maxLength={MAX_LENGTH_INPUT_TEXT}
                disable={validation}
              />
            </FormGroup>
          )}

          {watchHasDisease && especificacaoDinamica?.local && (
            <FormGroup>
              <TextInputDisease
                name={`${inputName}.especificacao[0].local`}
                label={especificacaoDinamica.local}
                maxLength={MAX_LENGTH_INPUT_TEXT}
                disable={validation}
              />
            </FormGroup>
          )}

          {watchHasDisease && especificacaoDinamica?.grau && (
            <FormGroup>
              <TextInputDisease
                name={`${inputName}.especificacao[0].grau`}
                label={especificacaoDinamica.grau}
                maxLength={MAX_LENGTH_INPUT_TEXT}
                disable={validation}
              />
            </FormGroup>
          )}

          {watchHasDisease && especificacaoManual && (
            <FormGroup>
              <TextInputDisease
                name={`${inputName}.especificacaoManual`}
                label={especificacaoManual}
                maxLength={MAX_LENGTH_INPUT_TEXT}
                disable={validation}
              />
            </FormGroup>
          )}

          {watchHasDisease && especificacaoManualAlteracoes && validation && (
            <FormGroup>
              <TextInputDisease
                name={`${inputName}.especificacaoManualAlteracoes`}
                label={especificacaoManualAlteracoes}
                maxLength={MAX_LENGTH_INPUT_TEXT}
              />
            </FormGroup>
          )}

          {!especificacaoDinamica && (
            <>
              <Grid
                container
                item
                justifyContent="flex-start"
                alignItems="center"
                xs={12}
              >
                <Typography variant="subtitle1" color="textSecondary">
                  Selecione:
                </Typography>
              </Grid>
              <Grid container item>
                <Grid container item direction="column">
                  {disease.especificacao.map((choice, i) => (
                    <CheckboxDisease
                      key={JSON.stringify(choice)}
                      name={`${inputName}.especificacao[${i}]`}
                      disease={choice}
                    />
                  ))}
                </Grid>
              </Grid>
            </>
          )}
        </Collapse>
      </Paper>
    </Grid>
  );
};

export default QuestionAccordion;
