import React, { useState } from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  Paper,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { NotificacaoOperadora } from 'components';

import { useFormData, usePage, useTheme } from 'hooks';

interface IFormValues {
  declaracao: string;
}

const schema = yup.object().shape({
  declaracao: yup
    .string()
    .oneOf(
      [
        'semOrientacaoMedica',
        'orientacaoMedicaOperadora',
        'orientacaoMedicaParticular',
      ],
      'É necessário selecionar uma das opções para prosseguir.',
    ),
});

const useStyles = (theme: Theme): any => ({
  paper: {
    padding: '1.5rem',
    [theme.breakpoints.down(465)]: {
      padding: '1rem',
    },
    '& > h5': {
      marginBottom: 3,
    },
  },
  declaracaoText: {
    textAlign: 'justify',
  },
  form: {
    paddingTop: 3,
  },
  radioGroup: {
    width: '100%',
  },
  formAction: {
    margin: '30px auto 0',
    width: '100%',
    maxWidth: 650,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    paddingBottom: '10px',
  },
  pageFooterSm: {
    margin: '30px auto 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'inherit',
    rowGap: 25,
  },
  button: {
    width: '110px',
  },
});

const Declaracao: React.FC = () => {
  const { systemTheme } = useTheme();
  const [showModal, setShowModal] = useState(false);

  const classes = useStyles(systemTheme);
  const matchesSm = useMediaQuery('(min-width: 600px)');
  const { nextPage, previousPage } = usePage();
  const {
    formDataResponse: {
      semOrientacaoMedica,
      orientacaoMedicaOperadora,
      orientacaoMedicaParticular,
    },
    setFormDataResponse,
  } = useFormData();

  let declaracaoDefault = '';

  if (semOrientacaoMedica) {
    declaracaoDefault = 'semOrientacaoMedica';
  } else if (orientacaoMedicaOperadora) {
    declaracaoDefault = 'orientacaoMedicaOperadora';
  } else if (orientacaoMedicaParticular) {
    declaracaoDefault = 'orientacaoMedicaParticular';
  }

  const { control, formState, handleSubmit } = useForm<IFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      declaracao: declaracaoDefault,
    },
  });

  const handleOpenModal = (): void => {
    setShowModal(true);
  };

  const handleCloseModal = (): void => {
    setShowModal(false);
  };

  const onSubmit: SubmitHandler<IFormValues> = ({ declaracao }) => {
    setFormDataResponse(prevState => ({
      ...prevState,
      semOrientacaoMedica: declaracao === 'semOrientacaoMedica',
      orientacaoMedicaOperadora: declaracao === 'orientacaoMedicaOperadora',
      orientacaoMedicaParticular: declaracao === 'orientacaoMedicaParticular',
    }));

    if (declaracao === 'semOrientacaoMedica') {
      nextPage();
    } else {
      handleOpenModal();
    }
  };

  return (
    <>
      <Paper elevation={5} sx={classes.paper}>
        <Typography variant="h5">Declaração</Typography>
        <Box sx={classes.declaracaoText}>
          <Typography>
            Declaro para os devidos fins que as informações prestadas nesta
            Declaração de Saúde, relativas a mim e aos meus dependentes, foram
            espontaneamente feitas, são verdadeiras e completas, e assumo
            inteira responsabilidade pelas mesmas. Estou ciente que a omissão de
            fatos e informações que possam influir no correto enquadramento das
            coberturas, poderá ser considerado como comportamento fraudulento,
            implicando na rescisão do contrato, além de estar obrigado a arcar
            com os custos dos atendimentos obtidos. Comprometo-me a prestar toda
            e qualquer outra informação adicional que vier a ser solicitada, bem
            como autorizo médicos, clínicas e quaisquer entidades públicas ou
            privadas de saúde, a enviarem à UNIMED as informações de que ela
            necessitar sobre o meu estado de saúde e de meus dependentes,
            resultados de exames e tratamentos instituídos isentando-a, ou seus
            cooperados, de qualquer responsabilidade que implique em ofensa ao
            sigilo profissional.
          </Typography>
        </Box>

        <Box
          component="form"
          sx={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box sx={classes.radioGroup}>
            <FormControl
              component="fieldset"
              error={!!formState.errors.declaracao}
            >
              <Controller
                control={control}
                name="declaracao"
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      label="Declaro que dispensei a orientação de um profissional médico"
                      control={<Radio />}
                      value="semOrientacaoMedica"
                    />
                    <FormControlLabel
                      label="Declaro que fui orientado por um médico da operadora"
                      control={<Radio />}
                      value="orientacaoMedicaOperadora"
                    />
                    <FormControlLabel
                      label="Declaro que fui orientado por um médico particular"
                      control={<Radio />}
                      value="orientacaoMedicaParticular"
                    />
                  </RadioGroup>
                )}
              />
              {!!formState.errors.declaracao && (
                <FormHelperText>
                  {formState.errors.declaracao.message}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box sx={matchesSm ? classes.formAction : classes.pageFooterSm}>
            <Button
              type="button"
              color="secondary"
              sx={classes.button}
              variant="outlined"
              startIcon={<Icon>chevron_left</Icon>}
              onClick={previousPage}
            >
              Voltar
            </Button>
            <Button
              type="submit"
              color="primary"
              sx={classes.button}
              variant="contained"
              endIcon={<Icon>chevron_right</Icon>}
              disabled={!formState.isValid}
            >
              Avançar
            </Button>
          </Box>
        </Box>
      </Paper>
      <NotificacaoOperadora open={showModal} onClose={handleCloseModal} />
    </>
  );
};

export default Declaracao;
