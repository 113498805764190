import React from 'react';

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { useTheme } from 'hooks';

import NavigationButton from '../../components/NavigationButton';

import step1Img from '../../assets/step1.svg';
import step2Img from '../../assets/step2.svg';
import step3Img from '../../assets/step3.svg';
import step4Img from '../../assets/step4.svg';
import step5Img from '../../assets/step5.svg';
import step6Img from '../../assets/step6.svg';

const useStyles = (theme: Theme): any => ({
  page: { paddingTop: 5 },
  titleXs: {
    marginTop: '35px',
    fontSize: '1.7rem',
  },
  wrapper: {
    marginTop: 10,
    width: '100%',
    display: 'grid',
    justifyItems: 'center',
    gridTemplateColumns: '1fr',
    rowGap: 5,
  },
  wrapperXs: {
    marginTop: 5,
    width: '100%',
    display: 'grid',
    justifyItems: 'center',
    gridTemplateColumns: '1fr',
    rowGap: 5,
  },
  card: {
    width: '100%',
    maxWidth: 650,
    transition: '0.3s',
    height: 'fit-content',
  },
  media: {
    height: 425,
    width: '100%',
    margin: 'auto',
    transition: '0.3s',
    [theme.breakpoints.down(690)]: {
      height: 360,
    },
    [theme.breakpoints.down(625)]: {
      height: 300,
    },
    [theme.breakpoints.down(535)]: {
      height: 250,
    },
  },
  mediaXs: {
    height: 280,
    width: '100%',
    margin: 'auto',
    transition: '0.3s',
    [theme.breakpoints.down(426)]: {
      height: 250,
    },
    [theme.breakpoints.down(376)]: {
      height: 219,
    },
    [theme.breakpoints.down(321)]: {
      height: 185,
    },
  },
  pageFooter: {
    maxWidth: 650,
    width: '100%',
    margin: '30px auto 0',
    alignItems: 'center',
    paddingBottom: 10,
  },
});

const Tutorial: React.FC = () => {
  const { systemTheme } = useTheme();

  const classes = useStyles(systemTheme);
  const matchesXs = useMediaQuery('(min-width: 465px)');

  return (
    <Box sx={classes.page}>
      <Typography
        variant="h3"
        align="center"
        sx={matchesXs ? undefined : classes.titleXs}
      >
        Guia de Preenchimento
      </Typography>

      <Box sx={matchesXs ? classes.wrapper : classes.wrapperXs}>
        <Card elevation={5} sx={classes.card}>
          <CardMedia
            sx={matchesXs ? classes.media : classes.mediaXs}
            image={step1Img}
            title="Step1 tutorial"
          />

          <CardContent>
            <Typography variant="h5" component="h2">
              Informações Importantes
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <b>Passo 1:</b> Dados pessoais do proprietário e de seu(s)
              dependente(s).
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <b>Passo 2:</b> Confira as informações preenchidas e confirme no
              botão &quot;Avançar&quot;.
            </Typography>
          </CardContent>
        </Card>

        <Card elevation={5} sx={classes.card}>
          <CardMedia
            sx={matchesXs ? classes.media : classes.mediaXs}
            image={step2Img}
            title="Step1 tutorial"
          />

          <CardContent>
            <Typography variant="h5" component="h2">
              Dados Pessoais
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <b>Passo 1:</b> Inclua peso e altura para o cálculo do IMC do
              proprietário e de seu(s) dependente(s).
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <b>Passo 2:</b> Confira as informações preenchidas e confirme
              clicando no botão &quot;Avançar&quot;.
            </Typography>
          </CardContent>
        </Card>

        <Card elevation={5} sx={classes.card}>
          <CardMedia
            sx={matchesXs ? classes.media : classes.mediaXs}
            image={step3Img}
            title="Step1 tutorial"
          />

          <CardContent>
            <Typography variant="h5" component="h2">
              Declaração de Saúde
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <b>Passo 1:</b> Selecione SIM ou NÃO nos campos de doenças e
              lesões preexistentes para cada beneficiário.
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <b>Passo 2:</b> Confira as informações preenchidas e confirme
              clicando no botão &quot;Avançar&quot;.
            </Typography>
          </CardContent>
        </Card>

        <Card elevation={5} sx={classes.card}>
          <CardMedia
            sx={matchesXs ? classes.media : classes.mediaXs}
            image={step4Img}
            title="Step1 tutorial"
          />

          <CardContent>
            <Typography variant="h5" component="h2">
              Documentação Complementar
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <b>Passo 1:</b> Primeiramente, selecione o nome do membro da
              família que deseja enviar o documento e depois busque o documento
              em seu computador. Para enviá-lo, clique no botão
              &quot;Confirmar&quot;.
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <b>Passo 2:</b> Adicione se desejar uma observação geral referente
              ao preenchimento desta declaração de saúde.
            </Typography>
          </CardContent>
        </Card>

        <Card elevation={5} sx={classes.card}>
          <CardMedia
            sx={matchesXs ? classes.media : classes.mediaXs}
            image={step5Img}
            title="Step1 tutorial"
          />

          <CardContent>
            <Typography variant="h5" component="h2">
              Resumo do Preenchimento
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <b>Passo 1:</b> Leia atentamente as informações referente ao
              preenchimento desta declaração de saúde.
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <b>Passo 2:</b> Clique no botão &quot;Avançar&quot; para ir para a
              próxima etapa.
            </Typography>
          </CardContent>
        </Card>

        <Card elevation={5} sx={classes.card}>
          <CardMedia
            sx={matchesXs ? classes.media : classes.mediaXs}
            image={step6Img}
            title="Step1 tutorial"
          />

          <CardContent>
            <Typography variant="h5" component="h2">
              Cobertura Parcial Temporária
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <b>Passo 1:</b> Leia atentamente toda a tabela de CPT.
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <b>Passo 2:</b> Concorde e selecione os campos obrigatórios e
              depois, confirme o envio da declaração de saúde.
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Box sx={classes.pageFooter}>
        <NavigationButton />
      </Box>
    </Box>
  );
};

export default Tutorial;
