import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { CpfInput } from 'components';
import { IFormValues } from 'components/CpfInput';

import { usePage, useAuth } from 'hooks';

import { api } from 'services';

import { token as storage } from 'utils';

const useStyles = (): any => ({
  title: {
    marginTop: 5,
  },
  titleXs: {
    marginTop: '85px',
    fontSize: '1.7rem',
  },
  paper: {
    padding: 5,
    maxWidth: 900,
    margin: '50px auto 0',
  },
  paperXs: {
    padding: 5,
    maxWidth: 750,
    heigh: 150,
    margin: '50px auto 0',
  },
  logo: {
    width: 120,
    '&>img': {
      width: '100%',
    },
  },
  form: {
    padding: 20,
    fontSize: '1rem',
    textTransform: 'none',
  },
  text: {
    fontSize: '1rem',
    textTransform: 'none',
  },
  label: {
    marginTop: 5,
  },
  labelXs: {
    fontSize: '0.65rem',
    marginTop: 5,
    marginBottom: 5,
    textAlign: 'center',
  },
  labelTextXs: {
    fontSize: '0.65rem',
  },
  input: {
    marginTop: 5,
    paddingBottom: 10,
  },
  inputXs: {
    marginTop: 5,
    paddingBottom: 5,
  },
});

const schema = yup
  .object({
    cpf: yup
      .string()
      .length(4, 'Este campo deve conter 4 dígitos')
      .required('Este campo é obrigatório'),
  })
  .required();

const ValidacaoCPF: React.FC = () => {
  const classes = useStyles();
  const matchesXs = useMediaQuery('(min-width: 465px)');
  const { setCurrentPage } = usePage();
  const { session, setSession } = useAuth();

  const [attempts, setAttempts] = useState(5);
  const { handleSubmit, control, formState } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (!session.protocol) {
      toast.error(
        'Erro ao identificar o protocolo! Recarregue a página e tente novamente.',
      );
    }
  }, [session]);

  // Redireciona o usuário para página de erro após 5 tentativas
  useEffect(() => {
    if (attempts === 0) {
      setCurrentPage(-1);
    }
  }, [attempts, setCurrentPage]);

  const onSubmit: SubmitHandler<IFormValues> = ({ cpf }): Promise<void> => {
    const protocolo = session.protocol;

    return api
      .post('preenchimento-formulario/valida-cpf', { cpf, protocolo })
      .then(({ data }) => {
        if (!data) {
          setAttempts(attempts - 1);
          toast.error('CPF inválido');
          return;
        }

        const { sessao, token } = data;

        storage.setAccessToken(token.access_token);
        storage.setRefreshToken(token.refresh_token);

        setSession(prevState => ({
          ...prevState,
          id: sessao.id,
          ipAddress: sessao.ip,
          createdAt: sessao.dataSessao,
          cpf,
        }));
      })
      .catch(err => {
        if (!err.response) {
          toast.error(
            'Problemas ao conectar no servidor! Tente novamente mais tarde.',
            { duration: 15000 },
          );
          return;
        }
        if (err.response?.status === 404) {
          toast.error('Os dígitos estão incorretos');
          return;
        }
        if (err.response?.data?.errorMessage?.[0]?.message) {
          toast.error(err.response?.data?.errorMessage?.[0]?.message);
        }
      });
  };

  return (
    <Box>
      <Typography
        sx={matchesXs ? classes.title : classes.titleXs}
        variant="h3"
        align="center"
      >
        Bem-vindo(a) à Declaração de Saúde!
      </Typography>

      <Paper sx={matchesXs ? classes.paper : classes.paperXs} elevation={5}>
        <form onSubmit={handleSubmit(onSubmit)} className={`${classes.form}`}>
          <Grid
            container
            justifyContent="center"
            sx={matchesXs ? classes.label : classes.labelXs}
          >
            <Typography sx={matchesXs ? undefined : classes.labelTextXs}>
              Digite os quatro primeiros dígitos do CPF do proprietário
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={matchesXs ? classes.input : classes.inputXs}
          >
            <Controller
              name="cpf"
              control={control}
              render={({
                field: { onBlur, onChange, ref },
                formState: { errors },
              }) => (
                <CpfInput
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  errorMessage={errors.cpf?.message}
                />
              )}
            />
          </Grid>
          {attempts < 5 && (
            <Typography variant="h6" color="error">
              Tentativas restantes: {attempts}
            </Typography>
          )}
          <Grid container item justifyContent="center" alignItems="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!formState.isValid || formState.isSubmitting}
            >
              continuar
            </Button>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default ValidacaoCPF;
