import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  Icon,
  InputLabel,
  List,
  MenuItem,
  Paper,
  Select,
  styled,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { useAuth, useFormData, usePage, useProtocol, useTheme } from 'hooks';

import { api } from 'services';

import { files as FileUtils, token } from 'utils';

interface BeneficiarioFile {
  dataInclusao: string;
  documento: string;
  id: number;
  idBeneficiario: string;
  nome: string;
  protocolo: string;
  sessao: string;
  tamanho: number;
  extensao: 'pdf' | 'jpg' | 'jpeg' | 'png';
}

interface StoredFile {
  stored: BeneficiarioFile;
  file: File;
}

interface SelectedFilesProps {
  [key: string]: {
    files: StoredFile[];
  };
}

interface FormValues {
  comment: string;
}

const MAX_LENGTH_INPUT_TEXT = 500;

const schema = yup.object().shape({
  comment: yup
    .string()
    .max(
      MAX_LENGTH_INPUT_TEXT,
      `O tamanho máximo é de ${MAX_LENGTH_INPUT_TEXT} caracteres`,
    ),
});

const useStyles = (theme: Theme): any => ({
  page: {
    paddingTop: 5,
    maxWidth: 850,
    margin: 'auto',
    alignSelf: 'center',
  },
  titleXs: {
    marginTop: '35px',
    fontSize: '1.7rem',
  },
  pageTitle: {
    marginBottom: 5,
  },
  pageFooter: {
    maxWidth: 650,
    width: '100%',
    margin: '30px auto 0',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px',
  },
  pageFooterSm: {
    margin: '30px auto 0',
    width: '100%',
    maxWidth: 650,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'inherit',
    paddingBottom: '10px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: 'auto',
    maxWidth: 850,
    rowGap: 5,
    [theme.breakpoints.down(465)]: {
      rowGap: 5,
    },
    justifyItems: 'center',
  },
  paper: {
    padding: '1.5rem',
    [theme.breakpoints.down(465)]: {
      padding: '1rem',
    },
  },
  userList: {
    marginBottom: 25,
    '&:first-of-type': {
      marginTop: 30,
    },
  },
  list: {
    display: 'block',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 0,
  },
  listed: {
    display: 'flex',
  },
  uploadAction: {
    marginTop: 50,
  },
  notRequired: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '5px',
  },
  notRequiredSm: {
    display: 'flex',
    flexDirection: 'column',
    columnGap: '5px',
  },
  input: {
    width: '100%',
  },
  boxInput: {
    display: 'block',
    '& > .MuiBox-root': {
      marginBottom: '1rem',
    },
  },
  boxInputSm: {
    display: 'flex',
    flexDirection: 'column',
    '& > .MuiBox-root': {
      marginBottom: '1rem',
    },
  },
  boxDocumentButtons: {
    width: 'fit-content',
    display: 'flex',
    justifySelf: 'end',
    alignSelf: 'end',
  },
  boxDocumentButtonsSm: {
    display: 'block',
    justifyContent: 'space-arround',
  },
  boxInputMd: {
    display: 'grid',
    gridTemplateColumns: '2fr',
    gridTemplateRows: '1fr',
    alignItems: 'end',
    columnGap: '10px',
  },
  boxInputAjust: {
    marginBottom: '23px',
    width: '100%',
  },
  resizeFont: {
    fontSize: '2.2rem',
  },
  filename: {
    width: 'auto',
    margin: '10px',
  },
  filenameMd: {
    width: '320px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  containerFiles: {
    marginTop: '30px',
  },
  helperText: {
    marginBottom: '10px',
  },
  boxButtons: {
    width: 'fit-content',
    display: 'flex',
    marginTop: '10px',
  },
  buttonAjust: {
    marginRight: '10px',
  },
  button: {
    width: 110,
  },
});

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const MAX_FILE_SIZE_TO_UPLOAD = 5;

const DocumentacaoComp: React.FC = () => {
  const { formDataResponse, setFormDataResponse, newChangeDs } = useFormData();
  const { beneficiarios } = formDataResponse;
  const { handleSubmit, control, formState } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { comment: formDataResponse.obsGeral || '' },
  });
  const { nextPage, previousPage, setCurrentPage } = usePage();
  const { session } = useAuth();
  const { systemTheme } = useTheme();

  const classes = useStyles(systemTheme);
  const matches = useMediaQuery('(min-width: 960px)');
  const matchesSm = useMediaQuery('(min-width: 610px)');
  const matchesXs = useMediaQuery('(min-width: 465px)');
  const protocolo = useProtocol();

  const [selectedFiles, setSelectedFiles] = useState<SelectedFilesProps>(
    {} as SelectedFilesProps,
  );
  const [currentUser, setCurrentUser] = useState('');
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<
    'NOT_SEND' | 'SENDING' | 'SENDED'
  >('NOT_SEND');
  const [loadingFiles, setLoadingFiles] = useState(false);

  const fileInput = useRef<HTMLInputElement>(null);

  const userFiles = useMemo(
    () => selectedFiles[currentUser] ?? { files: [] },
    [selectedFiles, currentUser],
  );

  const errorMessage = useMemo(() => {
    if (uploadProgress === 'SENDING') {
      return `O documento ${currentFile?.name} está sendo enviado para o nosso banco de dados.`;
    }
    if (currentFile) {
      return 'É necessário confirmar o documento selecionado.';
    }
    return '';
  }, [uploadProgress, currentFile]);

  const clearFileInput = (): void => {
    setCurrentUser('');
    setCurrentFile(null);

    if (fileInput.current) {
      fileInput.current.value = '';
    }
  };

  const sendFile = (file: File): void => {
    const url = '/documento-beneficiario';
    const headers = {
      Authorization: `Bearer ${token.getAccessToken()}`,
      'Content-Type': 'multipart/form-data',
    };

    const formData = new FormData();
    formData.append('file', file);
    formData.append('protocolo', protocolo);
    formData.append('sessao_id', session.id);
    formData.append('idBeneficiario', currentUser);

    setUploadProgress('SENDING');

    api
      .post(url, formData, { headers })
      .then(({ data }) => {
        const us = { ...userFiles };
        us.files.push({ file, stored: data as BeneficiarioFile });

        setUploadProgress('SENDED');

        setSelectedFiles(prevState => ({
          ...prevState,
          [currentUser]: us,
        }));

        clearFileInput();

        toast.success(
          `O documento ${file.name} foi salvo em nosso banco de dados!`,
        );
      })
      .catch(() => {
        setUploadProgress('NOT_SEND');

        toast.error(
          `Ocorreu um erro ao fazer o upload do documento ${file.name}.`,
        );
      });
  };

  const handleUser = (value: string): void => {
    setCurrentUser(value);
  };

  const handleFileInput = (files: FileList | null): void => {
    if (!files) {
      return;
    }

    const file = files.item(0);

    if (!file) {
      return;
    }
    if (
      !FileUtils.checkIfFilesAreCorrectType(file, ['pdf', 'png', 'jpeg', 'jpg'])
    ) {
      toast.error(`O arquivo ${file.name} não é do tipo permitido.`);
      return;
    }
    if (!FileUtils.checkMaxFileSize(file, MAX_FILE_SIZE_TO_UPLOAD)) {
      toast.error(`O arquivo ${file.name} excedeu o limite máximo de 5MB.`);
      return;
    }

    setCurrentFile(file);
  };

  const handleSaveFile = (): void => {
    if (!currentUser || !currentFile) {
      return;
    }

    const isEmpty =
      userFiles.files.filter(({ file }) => file.name === currentFile.name)
        .length === 0;

    if (!isEmpty) {
      toast.error(`O arquivo ${currentFile.name} já está inserido.`);
      return;
    }

    sendFile(currentFile);
  };

  const handleClickFile = (file: File): void => {
    FileUtils.openFile(file);
  };

  const handleRemoveFile = (beneficiarioFile: BeneficiarioFile): void => {
    const headers = { Authorization: `Bearer ${token.getAccessToken()}` };
    const { id, idBeneficiario } = beneficiarioFile;

    api
      .delete(`/documento-beneficiario/${id}`, { headers })
      .then(() => {
        setSelectedFiles(prevState => {
          const files = prevState[idBeneficiario].files.filter(
            ({ stored }) => stored?.id !== id,
          );

          return {
            ...prevState,
            [idBeneficiario]: { files },
          };
        });
        toast.success('O arquivo foi removido com sucesso!');
      })
      .catch(() => {
        toast.error(
          'Não foi possível remover o arquivo. Tente novamente mais tarde.',
        );
      });
  };

  const onSubmit: SubmitHandler<FormValues> = ({ comment }): void => {
    const formDataCopy = { ...formDataResponse };
    formDataCopy.obsGeral = comment;

    setFormDataResponse(formDataCopy);
    nextPage();
  };

  const fetchFiles = (): void => {
    const url = `/documento-beneficiario/${protocolo}`;
    const headers = {
      Authorization: `Bearer ${token.getAccessToken()}`,
    };

    setLoadingFiles(true);

    api
      .get(url, { headers })
      .then(({ data }) => {
        const beneficiariosFiles = {} as SelectedFilesProps;

        (data as BeneficiarioFile[]).forEach(beneficiarioFile => {
          const { documento, idBeneficiario, nome, extensao } =
            beneficiarioFile;

          if (!beneficiariosFiles[idBeneficiario]) {
            beneficiariosFiles[idBeneficiario] = { files: [] };
          }

          const filename = `${nome}.${extensao}`;
          const mimeType = FileUtils.getMimeTypeFromExtension(extensao);

          const file = FileUtils.getFileFromBase64(
            documento,
            filename,
            mimeType,
          );
          const storedFile: StoredFile = { stored: beneficiarioFile, file };

          beneficiariosFiles[idBeneficiario].files.push(storedFile);
        });

        setSelectedFiles(beneficiariosFiles);
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao carregar os documentos armazenados em nosso servidor.',
        );
      })
      .finally(() => {
        setLoadingFiles(false);
      });
  };

  const previousPageCondition = (): void => {
    if (newChangeDs) {
      setCurrentPage(-3);
    } else {
      previousPage();
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  return (
    <Box sx={classes.page}>
      <Box sx={classes.pageTitle}>
        <Typography
          variant="h3"
          align="center"
          sx={matchesXs ? undefined : classes.titleXs}
        >
          Documentação Complementar
        </Typography>
      </Box>

      <Box sx={classes.wrapper}>
        <Paper elevation={5} sx={classes.paper}>
          <Box sx={matchesSm ? classes.notRequired : classes.notRequiredSm}>
            <Typography>
              {`Anexar documentos complementares${
                matches ? ' de tratamento para análise' : ''
              }`}
            </Typography>
            <Typography variant="overline" noWrap={false}>
              (Não Obrigatório)
            </Typography>
          </Box>

          <Box sx={matches ? classes.boxInputMd : classes.boxInput}>
            <Box sx={classes.boxInputAjust}>
              <FormControl fullWidth size="small">
                <InputLabel id="select-beneficiario-label">
                  Selecione o beneficiário
                </InputLabel>
                <Select
                  id="select-user-name"
                  labelId="select-beneficiario-label"
                  onChange={e => handleUser(e.target.value as string)}
                  value={currentUser}
                  label="Selecione"
                  sx={classes.input}
                >
                  <MenuItem disabled value="">
                    <em>Selecione o beneficiário</em>
                  </MenuItem>
                  {beneficiarios.map(user => (
                    <MenuItem
                      key={user.idBeneficiario}
                      value={user.idBeneficiario}
                    >
                      {user.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box>
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  component="label"
                  startIcon={<Icon>cloud_upload</Icon>}
                  size="small"
                >
                  Inserir Documento
                  <input
                    hidden
                    id="conteined-button-file"
                    type="file"
                    accept="application/pdf, image/png, image/jpeg"
                    onChange={e => handleFileInput(e.target.files)}
                    ref={fileInput}
                  />
                </Button>
                {!!currentFile && (
                  <Tooltip
                    title={currentFile.name}
                    placement="top"
                    sx={classes.filename}
                  >
                    <Typography>{currentFile.name}</Typography>
                  </Tooltip>
                )}
              </Box>
              <FormHelperText sx={classes.helperText}>
                Somente arquivos com extensão pdf, png ou jpeg, de até 5MB de
                tamanho. A resolução mínima para a imagem é de 1000x1000px.
              </FormHelperText>
            </Box>

            <Box sx={classes.boxButtons}>
              <Button
                aria-label="remove file"
                color="secondary"
                variant="outlined"
                onClick={clearFileInput}
                sx={classes.buttonAjust}
                disabled={!currentUser && !currentFile}
              >
                Cancelar
              </Button>
              <Button
                aria-label="add file"
                color="primary"
                variant="contained"
                onClick={handleSaveFile}
                disabled={
                  !currentUser || !currentFile || uploadProgress === 'SENDING'
                }
              >
                Confirmar
              </Button>
            </Box>
          </Box>

          <Box sx={classes.containerFiles}>
            {loadingFiles ? (
              <CircularProgress />
            ) : (
              Object.keys(selectedFiles).map(
                idBeneficiario =>
                  selectedFiles[idBeneficiario].files.length > 0 && (
                    <div key={idBeneficiario} style={classes.userList}>
                      <Typography variant="button">
                        {
                          beneficiarios.find(
                            user => user.idBeneficiario === idBeneficiario,
                          )?.nome
                        }
                      </Typography>

                      <List sx={classes.list}>
                        {selectedFiles[idBeneficiario].files.map(
                          ({ stored, file }) => (
                            <ListItem key={`${idBeneficiario}_${file.name}`}>
                              <Chip
                                sx={classes.listed}
                                key={`${idBeneficiario}_${file.name}`}
                                size="small"
                                label={file.name}
                                variant="filled"
                                onDelete={() => handleRemoveFile(stored)}
                                onClick={() => handleClickFile(file)}
                              />
                            </ListItem>
                          ),
                        )}
                      </List>
                    </div>
                  ),
              )
            )}
          </Box>
        </Paper>

        <Paper elevation={5} sx={classes.paper} component="form">
          <Box sx={matchesSm ? classes.notRequired : classes.notRequiredSm}>
            <Typography component="label" htmlFor="comment">
              Comentários e informações adicionais
            </Typography>
            <Typography variant="overline" noWrap={false}>
              (Não Obrigatório)
            </Typography>
          </Box>
          <Controller
            name="comment"
            control={control}
            render={({ field, formState: { errors } }) => (
              <TextField
                {...field}
                variant="outlined"
                minRows={7}
                maxRows={7}
                multiline
                inputProps={{ maxLength: MAX_LENGTH_INPUT_TEXT }}
                fullWidth
                error={!!errors.comment?.message}
                helperText={`${field.value.length}/${MAX_LENGTH_INPUT_TEXT}`}
              />
            )}
          />
        </Paper>
      </Box>

      <Box sx={matchesSm ? classes.pageFooter : classes.pageFooterSm}>
        <Button
          variant="outlined"
          color="secondary"
          sx={classes.button}
          startIcon={<Icon>arrow_back_ios_icon</Icon>}
          onClick={previousPageCondition}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={classes.button}
          endIcon={<Icon>arrow_forward_ios_icon</Icon>}
          onClick={handleSubmit(onSubmit)}
          disabled={!!errorMessage || !formState.isValid}
        >
          Avançar
        </Button>
      </Box>
    </Box>
  );
};

export default DocumentacaoComp;
