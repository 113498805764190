import { useContext } from 'react';
import { AuthContext } from 'context';

const useProtocol = (): string => {
  const { session } = useContext(AuthContext);

  return session.protocol;
};

export default useProtocol;
