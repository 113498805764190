import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Link,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { useFormData, useTheme, usePage } from 'hooks';

const useStyle = (theme: Theme): any => ({
  bolder: {
    fontWeight: 600,
    textAlign: 'center',
    padding: '5px',
  },
  paper: {
    display: 'grid',
    maxWidth: 850,
    width: '100%',
    padding: '1.5rem',
    marginTop: '1.5rem',
    [theme.breakpoints.down(695)]: {
      marginTop: '0rem',
    },
  },
  paperXs: {
    display: 'grid',
    maxWidth: 850,
    width: '100%',
    padding: '1rem',
    marginTop: '5rem',
  },
  paperHeader: {
    textAlign: 'center',
    paddingTop: 5,
  },
  paperBody: {
    padding: '1rem 0',
  },
  paperFooter: {
    padding: '1rem 0',
    '& fieldset': {
      paddingTop: '1rem',
    },
  },
  paperFooterAction: {
    marginTop: 2,
    textAlign: 'center',
    paddingBottom: '10px',
  },
  highlightedText: {
    border: '1px solid #000',
    margin: '1rem 0',
    '& > ul': {
      listStylePosition: 'inside',
      padding: 0,
      textAlign: 'justify',
      '& > li > p': {
        display: 'inline',
      },
    },
  },
  label: {
    textAlign: 'justify',
  },
});

type FormValues = {
  aceiteOrientacaoAns: boolean;
};

const schema = yup.object().shape({
  aceiteOrientacaoAns: yup
    .bool()
    .oneOf([true], 'Este campo é obrigatório para prosseguir'),
});

const CartaOrientacaoBeneficiario: React.FC = () => {
  const { formDataResponse, setFormDataResponse } = useFormData();
  const { nextPage } = usePage();
  const { systemTheme } = useTheme();

  const { control, handleSubmit, formState } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      aceiteOrientacaoAns: !!formDataResponse.aceiteOrientacaoAns,
    },
  });

  const matchesXs = useMediaQuery('(min-width: 465px)');

  const classes = useStyle(systemTheme);

  const onSubmit: SubmitHandler<FormValues> = ({ aceiteOrientacaoAns }) => {
    setFormDataResponse(prevState => {
      const state = { ...prevState };
      state.aceiteOrientacaoAns = aceiteOrientacaoAns;
      return state;
    });
    nextPage();
  };

  return (
    <Grid container justifyContent="center">
      <Paper elevation={5} sx={matchesXs ? classes.paper : classes.paperXs}>
        <Box sx={classes.paperHeader}>
          <Typography sx={classes.bolder}>
            CARTA DE ORIENTAÇÃO AO BENEFICIÁRIO
          </Typography>
        </Box>

        <Box sx={classes.paperBody}>
          <Box>
            <Typography>
              Prezado(a) Beneficiário(a),
              <br />
              <br />
            </Typography>
            <Typography align="justify" sx={{ textIndent: '5ch' }}>
              A <b>Agência Nacional de Saúde Suplementar (ANS)</b>, instituição
              que regula as atividades das operadoras de planos privados de
              assistência à saúde, e tem como missão defender o interesse
              público vem, por meio desta, prestar informações para o
              preenchimento da DECLARAÇÃO DE SAÚDE.
            </Typography>
          </Box>

          <Box className={`${classes.highlightedText}`}>
            <Typography align="center" sx={classes.bolder}>
              <br />O QUE É A DECLARAÇÃO DE SAÚDE?
            </Typography>
            <Typography align="justify" sx={{ textIndent: '5ch' }}>
              É o formulário que acompanha o Contrato do Plano de Saúde, onde o
              beneficiário ou seu representante legal deverá informar as doenças
              ou lesões preexistentes que saiba ser portador ou sofredor no
              momento da contratação do plano. Para o seu preenchimento, o
              beneficiário tem o direito de ser orientado, gratuitamente, por um
              médico credenciado/referenciado pela operadora. Se optar por um
              profissional de sua livre escolha, assumirá o custo desta opção.
              <br />
            </Typography>
            <Typography align="justify" sx={{ textIndent: '5ch' }}>
              Portanto, se o beneficiário (você) toma medicamentos regularmente,
              consulta médicos por problema de saúde do qual conhece o
              diagnóstico, fez qualquer exame que identificou alguma doença ou
              lesão, esteve internado ou submeteu-se a alguma cirurgia, DEVE
              DECLARAR ESTA DOENÇA OU LESÃO.
            </Typography>
          </Box>

          <Box className={`${classes.highlightedText}`}>
            <Typography align="center" sx={classes.bolder}>
              <br />
              AO DECLARAR AS DOENÇAS E/OU LESÕES QUE O BENEFICIÁRIO SAIBA SER
              PORTADOR NO MOMENTO DA CONTRATAÇÃO:
            </Typography>
            <ul>
              <li>
                <Typography align="justify">
                  A operadora NÃO poderá impedi-lo de contratar o plano de
                  saúde. Caso isto ocorra, encaminhe a denúncia à ANS.
                </Typography>
              </li>
              <li>
                <Typography align="justify">
                  A operadora deverá oferecer: cobertura total ou COBERTURA
                  PARCIAL TEMPORÁRIA (CPT), podendo ainda oferecer o Agravo, que
                  é um acréscimo no valor da mensalidade, pago ao plano privado
                  de assistência à saúde, para que se possa utilizar toda a
                  cobertura contratada, após os prazos de carências contratuais.
                </Typography>
              </li>
              <li>
                <Typography align="justify">
                  No caso de CPT, haverá restrição de cobertura para cirurgias,
                  leitos de alta tecnologia (UTI, unidade coronariana ou
                  neonatal) e procedimentos de alta complexidade – PAC
                  (tomografia, ressonância, etc.*) EXCLUSIVAMENTE relacionados à
                  doença ou lesão declarada, até 24 meses, contados desde a
                  assinatura do contrato. Após o período máximo de 24 meses da
                  assinatura contratual, a cobertura passará a ser integral de
                  acordo com o plano contratado.
                </Typography>
              </li>
              <li>
                <Typography align="justify">
                  NÃO haverá restrição de cobertura para consultas médicas,
                  internações não cirúrgicas, exames e procedimentos que não
                  sejam de alta complexidade, mesmo que relacionados à doença ou
                  lesão preexistente declarada, desde que cumpridos os prazos de
                  carências estabelecidas no contrato.
                </Typography>
              </li>
              <li>
                <Typography align="justify">
                  Não caberá alegação posterior de omissão de informação na
                  Declaração de Saúde por parte da operadora para esta doença ou
                  lesão.
                </Typography>
              </li>
            </ul>
          </Box>

          <Box className={`${classes.highlightedText}`}>
            <Typography align="center" sx={classes.bolder}>
              AO NÃO DECLARAR AS DOENÇAS E/OU LESÕES QUE O BENEFICIÁRIO SAIBA
              SER PORTADOR NO MOMENTO DA CONTRATAÇÃO:
            </Typography>
            <ul>
              <li>
                <Typography align="justify">
                  A operadora poderá suspeitar de omissão de informação e, neste
                  caso, deverá comunicar imediatamente ao beneficiário, podendo
                  oferecer CPT, ou solicitar abertura de processo administrativo
                  junto à ANS, denunciando a omissão da informação.
                </Typography>
              </li>
              <li>
                <Typography align="justify">
                  Comprovada a omissão de informação pelo beneficiário, a
                  operadora poderá RESCINDIR o contrato por FRAUDE e
                  responsabilizá-lo pelos procedimentos referentes à doença ou
                  lesão não declarada.
                </Typography>
              </li>
              <li>
                <Typography align="justify">
                  Até o julgamento final do processo pela ANS, NÃO poderá
                  ocorrer suspensão do atendimento nem rescisão do contrato.
                  Caso isto ocorra, encaminhe a denúncia à ANS.
                </Typography>
              </li>
            </ul>
            <br />
          </Box>

          <Box className={`${classes.highlightedText}`}>
            <Typography align="justify" sx={{ textIndent: '5ch' }}>
              <b>ATENÇÃO!</b> Se a operadora oferecer redução ou isenção de
              carência, isto não significa que dará cobertura assistencial para
              as doenças ou lesões que o beneficiário saiba ter no momento da
              assinatura contratual. Cobertura Parcial Temporária - CPT - NÃO é
              carência! Portanto, o beneficiário não deve deixar de informar se
              possui alguma doença ou lesão ao preencher a Declaração de Saúde!
            </Typography>
          </Box>

          <Box>
            <Typography align="justify">
              <br />* Para consultar a lista completa de procedimentos de alta
              complexidade – PAC, acesse o Rol de Procedimentos e Eventos em
              Saúde da ANS no endereço eletrônico:{' '}
              <b>
                <Link
                  href="https://www.ans.gov.br"
                  target="_blank"
                  sx={{ color: 'blue' }}
                >
                  www.ans.gov.br - Perfil Beneficiário.
                </Link>
              </b>
              <br /> Em caso de dúvidas, entre em contato com a ANS pelo
              telefone 0800-701-9656 ou consulte a página da ANS -{' '}
              <b>
                <Link
                  href="https://www.ans.gov.br"
                  target="_blank"
                  sx={{ color: 'blue' }}
                >
                  www.ans.gov.br - Perfil Beneficiário.
                </Link>
              </b>
            </Typography>
          </Box>
        </Box>

        <Box sx={classes.paperFooter}>
          <Grid container component="form" direction="column">
            <FormControl
              component="fieldset"
              error={!!formState.errors.aceiteOrientacaoAns}
            >
              <FormLabel component="legend">Aceite do proprietário</FormLabel>
              <FormControlLabel
                sx={classes.label}
                label="Declaro que estou ciente das informações contidas na carta de orientação ao beneficiário."
                control={
                  <Controller
                    name="aceiteOrientacaoAns"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                }
              />
              <FormHelperText>
                {formState.errors.aceiteOrientacaoAns
                  ? formState.errors.aceiteOrientacaoAns.message
                  : ' '}
              </FormHelperText>
            </FormControl>

            <Box sx={classes.paperFooterAction}>
              <Button
                variant="contained"
                color="primary"
                disabled={!formState.isValid}
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Avançar
              </Button>
            </Box>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export default CartaOrientacaoBeneficiario;
