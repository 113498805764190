import React from 'react';
import Lottie, { Options } from 'react-lottie';

import { Grid, Typography } from '@mui/material';

import NotFoundAnimation from '../../assets/lotties/laptop.json';

const useStyles = (): any => ({
  root: {
    height: '100%',
  },
  text: {
    maxWidth: 400,
    width: '100%',
    fontSize: '1rem',
    fontWeight: 200,
  },
  image: {
    maxWidth: 200,
    width: '100%',
  },
});

const defaultLottieOptions: Options = {
  loop: true,
  autoplay: true,
  animationData: NotFoundAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ErroValidaLink: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={classes.root}
    >
      <Grid item sx={classes.text}>
        <Typography>Página não encontrada</Typography>
      </Grid>
      <Grid item sx={classes.image}>
        <Lottie options={defaultLottieOptions} />
      </Grid>
    </Grid>
  );
};

export default ErroValidaLink;
