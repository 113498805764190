import React from 'react';
import Lottie, { Options } from 'react-lottie';

import { Grid, Typography } from '@mui/material';

import ErrorAnimation from '../../assets/lotties/error_animation.json';

const useStyles = (): any => ({
  root: {
    height: '100%',
  },
  text: {
    maxWidth: 400,
    width: '100%',
    fontSize: '1rem',
    fontWeight: 200,
  },
  image: {
    maxWidth: 200,
    width: '100%',
  },
});

const defaultLottieOptions: Options = {
  loop: false,
  autoplay: true,
  animationData: ErrorAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ErroExpiradoOuPreenchido: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={classes.root}
    >
      <Grid item sx={classes.text}>
        <Typography>
          Este link não é mais válido para preenchimento, pois a declaração de
          saúde já foi preenchida ou o prazo para acesso expirou. Em caso de
          dúvidas contate o seu RH.
        </Typography>
      </Grid>
      <Grid item sx={classes.image}>
        <Lottie options={defaultLottieOptions} />
      </Grid>
    </Grid>
  );
};

export default ErroExpiradoOuPreenchido;
