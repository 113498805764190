import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Toaster } from 'react-hot-toast';

import {
  Backdrop,
  CircularProgress,
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';

import { ILayout, IPalette, IThemeContext, ITypography } from '../interfaces';

const DEFAULT_THEME: IPalette = {
  primary: { main: '#00995D' },
  secondary: { main: '#1C5253' },
  background: { default: '#F9F9FF' },
};

const DEFAULT_THEME_TOAST: IPalette = {
  primary: { main: '#FFFFFF' },
  secondary: { main: '#1C5253' },
  background: { default: '#333333' },
};

const DEFAULT_THEME_TYPOGRAPHY: ITypography = {
  fontFamily: ['Roboto', 'sans-serif'].join(','),
};

const typography = {
  ...DEFAULT_THEME_TYPOGRAPHY,
};

const systemTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const ThemeContext = createContext<IThemeContext>({} as IThemeContext);

const ThemeProvider: React.FC = ({ children }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [palette, setPalette] = useState<IPalette>(DEFAULT_THEME);
  const [toaster, setToaster] = useState<IPalette>(DEFAULT_THEME_TOAST);
  const [layout, setLayout] = useState<ILayout>({} as ILayout);

  const theme = useMemo(() => createTheme({ palette, typography }), [palette]);

  const isLoaded = useMemo(() => Object.keys(layout).length > 0, [layout]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (layout.palette) {
      setPalette(prevState => ({
        ...prevState,
        ...layout.palette,
      }));
    }
  }, [layout.palette]);

  return (
    <ThemeContext.Provider
      value={{
        palette,
        setPalette,
        showLoading,
        setShowLoading,
        toaster,
        setToaster,
        layout,
        setLayout,
        isLoaded,
        systemTheme,
      }}
    >
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>

      <Backdrop
        open={showLoading}
        style={{
          color: '#fff',
          zIndex: 9999,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Toaster
        toastOptions={{
          style: {
            background: toaster.background.default,
            color: toaster.primary.main,
          },
        }}
      />
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
