import React, { useMemo } from 'react';

import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import prettyDate from 'utils/prettyDate';

import { useFormData, useProtocol, useTheme } from 'hooks';

import { Declaracao } from 'pages';

const useStyles = (theme: Theme): any => ({
  page: { paddingTop: 5 },
  pageTitle: { marginBottom: 5 },
  pageTitleXs: { marginBottom: 5 },
  titleXs: {
    marginTop: '35px',
    fontSize: '1.7rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 5,
    [theme.breakpoints.down(465)]: {
      rowGap: 5,
    },
    maxWidth: 850,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  pageFooter: {
    maxWidth: 390,
    width: '100%',
    margin: '30px auto 0',
  },
  grid: {
    lineHeight: '10px',
  },
});

const Resumo: React.FC = () => {
  const { systemTheme } = useTheme();
  const { formDataResponse } = useFormData();

  const classes = useStyles(systemTheme);
  const matchesXs = useMediaQuery('(min-width: 465px)');
  const nrProtocolo = useProtocol();

  const beneficiarios = useMemo(
    () => formDataResponse.beneficiarios,
    [formDataResponse],
  );

  const titular = useMemo(() => formDataResponse.titular, [formDataResponse]);

  return (
    <Box sx={classes.page}>
      <Box sx={matchesXs ? classes.pageTitle : classes.pageTitleXs}>
        <Typography
          variant="h3"
          align="center"
          sx={matchesXs ? undefined : classes.titleXs}
        >
          Resumo Geral
        </Typography>
      </Box>

      <Box sx={classes.wrapper}>
        <Paper elevation={5}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h5">
              Informações sobre a Declaração de Saúde
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column" sx={classes.grid}>
              <Typography>
                <b>Número de Protocolo:</b> {nrProtocolo}
              </Typography>
              <br />
              <Typography>
                <b>Nome do Proprietário:</b> {titular.nome}
              </Typography>
              <br />
              <Typography>
                <b>CPF do Proprietário:</b> {titular.cpf}
              </Typography>
              <br />
              <Typography>
                <b>Data de Preenchimento:</b> {prettyDate()}
              </Typography>
              <br />
              <Typography>
                <b>Total de Beneficiários:</b> {beneficiarios.length}
              </Typography>
            </Grid>
          </AccordionDetails>
        </Paper>

        <Paper elevation={5}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h5">
              Doenças ou Lesões Preexistentes
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column">
              {beneficiarios.map(b => {
                const perguntasPossui = b.perguntas.filter(
                  p => p.possui === true,
                );
                const perguntasPossuiLength = perguntasPossui.length;

                return (
                  <Box key={b.idBeneficiario}>
                    <Typography key={b.idBeneficiario}>
                      {perguntasPossuiLength === 0
                        ? `${b.nome} não registrou doenças.`
                        : `${b.nome} registrou ${perguntasPossuiLength} doença(s).`}
                    </Typography>
                    {perguntasPossuiLength > 0 && (
                      <List dense>
                        {perguntasPossui.map(pergunta => (
                          <ListItem key={`${pergunta.id}_${b.nome}`}>
                            <ListItemText
                              primary={pergunta.especificacao[0]?.titulo}
                              secondary={pergunta.titulo}
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                    <br />
                  </Box>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Paper>

        <Declaracao />
      </Box>
    </Box>
  );
};

export default Resumo;
