/* eslint-disable react/no-danger */
import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from '@mui/material';

import { useTheme } from 'hooks';

type NotificacaoOperadoraProps = {
  open: boolean;
  onClose: () => void;
};

const useStyles = (): any => ({
  dialogContent: {
    fontSize: '1rem',
    fontFamily: '"Roboto","Arial", sans-serif',
    fontWeight: 400,
    textAlign: 'justify',
    '& > p': {
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
      marginBottom: 20,
    },
  },
});

const NotificacaoOperadora: React.FC<NotificacaoOperadoraProps> = ({
  open,
  onClose,
}) => {
  const matchesXs = useMediaQuery('(max-width: 465px)');
  const classes = useStyles();
  const { layout } = useTheme();

  return (
    <Dialog open={open} onClose={onClose} fullScreen={matchesXs}>
      <DialogTitle>Entre em contato conosco</DialogTitle>
      <DialogContent>
        <Box
          sx={classes.dialogContent}
          dangerouslySetInnerHTML={{ __html: layout.contactUs.html }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificacaoOperadora;
