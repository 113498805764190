import React, { useState } from 'react';

import {
  Fade,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  Theme,
} from '@mui/material';

import SignatureCanvas from 'react-signature-canvas';
import { useTheme } from 'hooks';

type SignaturePadProps = {
  signRef: React.RefObject<SignatureCanvas>;
};

const useStyles = (theme: Theme): any => ({
  label: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 1),
  },

  signatureCanvasControl: {
    position: 'relative',
  },

  signatureCanvas: {
    borderRadius: '4px',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],

    '&.hover': {
      borderColor: theme.palette.grey[500],
    },
    '&.focus': {
      borderColor: theme.palette.primary.main,
    },
  },

  signatureCanvasAction: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
});

const SignaturePad: React.FC<SignaturePadProps> = ({ signRef }) => {
  const { systemTheme } = useTheme();
  const classes = useStyles(systemTheme);
  const [hover, setHover] = useState(false);
  const [focused, setFocused] = useState(false);
  const [hasData, setHasData] = useState(false);

  const handleMouseEnter = (): void => setHover(true);
  const handleMouseLeave = (): void => setHover(false);

  const handleLockUpdate = (): void => {
    setFocused(true);
    setHasData(true);
  };

  const handleUpdateDate = (): void => setFocused(false);

  const handleClear = (): void => {
    if (signRef.current) {
      signRef.current.clear();
    }
    setHasData(false);
  };

  return (
    <FormControl
      variant="outlined"
      color="primary"
      focused={focused}
      sx={classes.signatureCanvasControl}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <InputLabel
        variant="outlined"
        htmlFor="canvas-signature"
        sx={classes.label}
        shrink={hasData}
      >
        Assinar
      </InputLabel>

      <SignatureCanvas
        ref={signRef}
        penColor="#3d3d3d"
        canvasProps={{
          width: 500,
          height: 200,
          className: `${classes.signatureCanvas} ${focused ? 'focus' : ''} ${
            hover ? 'hover' : ''
          }`,
        }}
        onBegin={handleLockUpdate}
        onEnd={handleUpdateDate}
      />
      <Fade in={hasData}>
        <IconButton
          size="small"
          sx={classes.signatureCanvasAction}
          onClick={handleClear}
        >
          <Icon>clear</Icon>
        </IconButton>
      </Fade>
    </FormControl>
  );
};

export default SignaturePad;
