import React, { useEffect, useMemo } from 'react';
import InputMask from 'react-input-mask';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Box,
  Button,
  Icon,
  InputAdornment,
  Paper,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { mapValues } from 'lodash';

import { usePage, useFormData, useTheme } from 'hooks';

interface IHeightWeight {
  height: string;
  weight: string;
}

interface IFormValues {
  [key: string]: IHeightWeight;
}

const schema = yup.lazy(obj =>
  yup
    .object(
      mapValues(obj, () =>
        yup.object({
          height: yup
            .string()
            .required('Este campo é obrigatório')
            .test(
              'min',
              'A altura deve ser maior que zero',
              val => Number(val?.replace(',', '.')) > 0,
            )
            .test(
              'max',
              'A altura deve ser menor ou igual a 2,72m',
              val => Number(val?.replace(',', '.')) <= 2.72,
            ),
          weight: yup
            .string()
            .required('Este campo é obrigatório')
            .test(
              'min',
              'O peso deve ser maior que zero',
              val => Number(val?.replace(',', '.')) > 0,
            )
            .test(
              'max',
              'O peso deve ser menor ou igual a 597kg',
              val => Number(val?.replace(',', '.')) <= 597,
            ),
        }),
      ),
    )
    .required(),
);

const useStyles = (theme: Theme): any => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 5,
  },
  titleXs: {
    marginTop: '35px',
    fontSize: '1.7rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    alignItems: 'center',
    marginTop: 5,
  },
  wrapperXs: {
    display: 'flex',
    marginTop: 5,
    width: '100%',
    flexDirection: 'column',
    gap: 5,
    justifyContent: 'center',
    justifyItems: 'center',
    rowGap: 5,
  },
  paper: {
    display: 'flex',
    maxWidth: 790,
    width: '100%',
    padding: 3,
    [theme.breakpoints.down(885)]: {
      display: 'block',
      width: '95%',
    },
    [theme.breakpoints.down(465)]: {
      width: 'auto',
    },
  },
  inputs: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: 3,
    [theme.breakpoints.down(645)]: {
      gridTemplateColumns: '1fr',
      rowGap: 3,
    },
  },
  pageFooter: {
    margin: '30px auto 0',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 650,
    width: '100%',
    paddingBottom: '10px',
  },
  button: {
    width: '110px',
  },
});

const IMC: React.FC = () => {
  const { systemTheme } = useTheme();
  const { nextPage, previousPage, setCurrentPage } = usePage();
  const {
    beneficiariosConfig,
    beneficiariosResponse,
    formDataResponse,
    newChangeDs,
    pastBenefitForm,
    setFormDataResponse,
  } = useFormData();

  const classes = useStyles(systemTheme);
  const matchesXs = useMediaQuery('(min-width: 465px)');

  const titular = useMemo(() => formDataResponse.titular, [formDataResponse]);

  const defaultValues = useMemo(() => {
    const values: IFormValues = {};

    beneficiariosConfig.forEach(({ idBeneficiario }) => {
      const beneficiario = beneficiariosResponse.find(
        b => b.idBeneficiario === idBeneficiario,
      );
      let height = '';
      let weight = '';

      if (beneficiario) {
        height = beneficiario?.altura ?? '';
        weight = beneficiario?.peso ?? '';
      }
      values[idBeneficiario] = { height, weight };
    });

    return values;
  }, [beneficiariosConfig, beneficiariosResponse]);

  const { handleSubmit, formState, control, reset } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const onSubmit: SubmitHandler<IFormValues> = values => {
    const beneficiaryValidation = pastBenefitForm.beneficiarios?.filter(
      item => item.perguntas.length === 0,
    );
    const ids = Object.keys(values);
    const beneficiarios = [...beneficiariosConfig]
      .filter(({ idBeneficiario }) => ids.includes(idBeneficiario))
      .map(beneficiario => {
        const { idBeneficiario } = beneficiario;
        const beneficiarioResponse = beneficiariosResponse.find(
          b => b.idBeneficiario === idBeneficiario,
        );

        return {
          ...beneficiario,
          ...beneficiarioResponse,
          altura: values[idBeneficiario].height,
          peso: values[idBeneficiario].weight,
        };
      });

    setFormDataResponse(prevState => ({
      ...prevState,
      beneficiarios,
    }));
    if (newChangeDs && beneficiaryValidation.length === 0) {
      setCurrentPage(6);
    } else {
      nextPage();
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  return (
    <Box sx={classes.page} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography
        variant="h3"
        align="center"
        sx={matchesXs ? undefined : classes.titleXs}
      >
        Dados Pessoais
      </Typography>

      <Box sx={matchesXs ? classes.wrapper : classes.wrapperXs}>
        {beneficiariosConfig.map(beneficiario => (
          <Paper
            sx={classes.paper}
            key={beneficiario.idBeneficiario}
            elevation={5}
          >
            <Box>
              <Typography variant="h5">{beneficiario.nome}</Typography>
              <Typography gutterBottom variant="subtitle1">
                {titular.cpf === beneficiario.cpf
                  ? 'Proprietário'
                  : 'Dependente'}
              </Typography>
              <Box sx={classes.inputs}>
                <TextField
                  fullWidth
                  disabled
                  label="Data de nascimento"
                  defaultValue={beneficiario.dataNasc}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>cake</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
                <Controller
                  name={`${beneficiario.idBeneficiario}.weight`}
                  control={control}
                  render={({
                    field: { onBlur, onChange, ref, value },
                    formState: { errors },
                  }) => (
                    <InputMask
                      ref={ref}
                      onBlur={onBlur}
                      onChange={onChange}
                      mask="9??????"
                      maskChar={null}
                      alwaysShowMask={false}
                      value={value}
                      formatChars={{
                        9: '[0-9]',
                        '?': '[0-9,]',
                      }}
                    >
                      {() => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Peso(kg)"
                          placeholder="Ex: 70"
                          error={
                            !!errors[beneficiario.idBeneficiario]?.weight
                              ?.message
                          }
                          helperText={
                            errors[beneficiario.idBeneficiario]?.weight?.message
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon>balance</Icon>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </InputMask>
                  )}
                />

                <Controller
                  name={`${beneficiario.idBeneficiario}.height`}
                  control={control}
                  render={({
                    field: { onBlur, onChange, ref, value },
                    formState: { errors },
                  }) => (
                    <InputMask
                      ref={ref}
                      onBlur={onBlur}
                      onChange={onChange}
                      mask="9,99"
                      maskChar={null}
                      alwaysShowMask={false}
                      value={value}
                    >
                      {() => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Altura(m)"
                          placeholder="Ex: 1,70"
                          error={
                            !!errors[beneficiario.idBeneficiario]?.height
                              ?.message
                          }
                          helperText={
                            errors[beneficiario.idBeneficiario]?.height?.message
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon>straighten</Icon>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </InputMask>
                  )}
                />
              </Box>
            </Box>
          </Paper>
        ))}
      </Box>

      <Box sx={classes.pageFooter}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<Icon>arrow_back_ios_icon</Icon>}
          onClick={previousPage}
          sx={classes.button}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<Icon>arrow_forward_ios_icon</Icon>}
          type="submit"
          sx={classes.button}
          disabled={!formState.isValid}
        >
          Avançar
        </Button>
      </Box>
    </Box>
  );
};

export default IMC;
