import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
} from '@mui/material';

const useStyles = (): any => ({
  actionButtons: {
    alignSelf: 'end',
    gap: 5,
  },
});

type SessionDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleClick: () => void;
  dateLastAccess: string;
};

const SessionDialog: React.FC<SessionDialogProps> = ({
  open,
  handleClose,
  handleClick,
  dateLastAccess,
}) => {
  const matchesXs = useMediaQuery('(max-width: 465px)');
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={matchesXs}>
      <DialogTitle>Bem-vindo(a) novamente!</DialogTitle>
      <DialogContent>
        <Typography gutterBottom align="justify">
          Identificamos que você acessou anteriormente o nosso portal e que já
          realizou o preenchimento de algumas informações da declaração de
          saúde. Caso queira continuar de onde parou, basta clicar no botão
          &quot;Carregar&quot; abaixo.
        </Typography>

        <Typography>Último acesso efetuado em {dateLastAccess}</Typography>
      </DialogContent>
      <DialogActions sx={classes.actionButtons}>
        <Button onClick={handleClose} variant="outlined">
          Cancelar
        </Button>
        <Button onClick={handleClick} variant="contained" color="primary">
          Carregar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionDialog;
