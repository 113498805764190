import React, { createContext, useMemo, useState } from 'react';

import { IAuthContext, ISession } from '../interfaces';

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [session, setSession] = useState<ISession>({} as ISession);

  const isAuthenticated = useMemo(() => {
    const { id, cpf, protocol } = session;

    return !!id && !!cpf && !!protocol;
  }, [session]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        session,
        setSession,
        token,
        setToken,
        refreshToken,
        setRefreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
