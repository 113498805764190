import React, { useState } from 'react';

import {
  Typography,
  Paper,
  useMediaQuery,
  Box,
  Button,
  Icon,
  Collapse,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { useFormData, usePage } from 'hooks';
import { IQuestionario } from 'context';

const useStyles = (): any => ({
  page: { paddingTop: 5 },
  wrapper: {
    width: '100%',
    marginTop: 5,
    display: 'grid',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    rowGap: 5,
  },
  wrapperXs: {
    marginTop: '35px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  card: {
    display: 'flex',
    padding: 30,
    width: '100%',
    maxWidth: 790,
  },
  cardSm: {
    display: 'block',
    justifyContent: 'space-between',
    padding: 30,
    width: '100%',
  },
  boxSm: {
    marginBottom: 10,
  },
  title: { fontWeight: 'bold' },
  titleXs: {
    marginTop: '35px',
    fontSize: '1.7rem',
  },
  pageFooter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '30px auto 0',
    maxWidth: 650,
    paddingBottom: 10,
    width: '100%',
  },
  changeButton: {
    width: '110px',
  },
});

const FormularioIdentificado: React.FC = () => {
  const classes = useStyles();
  const matchesXs = useMediaQuery('(min-width: 465px)');

  const { pastBenefitForm, setNewChangeDs, savePreviousDsInfo } = useFormData();
  const { setCurrentPage } = usePage();

  const benefitData = pastBenefitForm.beneficiarios;

  const [collapse, setCollapse] = useState<{ [key: number]: boolean }>({});

  const handleClick = (key: number): any => {
    setCollapse({ [key]: !collapse[key] });
  };

  const columns: GridColDef[] = [
    {
      field: 'illness',
      flex: 0.8,
      headerName: 'Categoria de Doença',
    },
    {
      field: 'description',
      align: 'center',
      flex: 0.8,
      headerAlign: 'center',
      headerName: 'Descrição',
    },
    {
      field: 'information',
      align: 'center',
      flex: 0.7,
      headerAlign: 'center',
      headerName: 'Informações',
    },
  ];

  const rows = (questions: IQuestionario[]): any =>
    questions
      .filter(({ possui }) => possui === true)
      .map(({ id, titulo, especificacao, especificacaoManual }) => ({
        id,
        illness: titulo,
        description: especificacao[0].titulo,
        information: especificacaoManual,
      }));

  const changePreviousDs = (): void => {
    setNewChangeDs(false);
    savePreviousDsInfo();
    setCurrentPage(4);
  };

  const savePreviousDs = (): void => {
    setNewChangeDs(true);
    savePreviousDsInfo();
    setCurrentPage(4);
  };

  return (
    <Box sx={classes.page}>
      <Typography
        variant="h5"
        align="center"
        sx={matchesXs ? undefined : classes.titleXs}
      >
        Identificamos que você preencheu uma Declaração de Saúde anteriormente.
        Caso queira utilizar as informações já preenchidas, basta clicar no
        botão Salvar e Continuar.
      </Typography>
      <Box sx={matchesXs ? classes.wrapper : classes.wrapperXs}>
        {benefitData?.map(item => (
          <Paper
            elevation={5}
            key={item.idBeneficiario}
            style={{ width: '100%' }}
          >
            <Box
              display="flex"
              onClick={() => handleClick(Number(item.idBeneficiario))}
              p={3}
            >
              <Typography style={{ marginRight: 10 }}>
                <b>{item.nome}</b>
                {item.dataPreenchimento &&
                  ` - Preenchida em: ${item.dataPreenchimento}`}
              </Typography>
              {!collapse[Number(item.idBeneficiario)] ? (
                <Icon>expand_more_ios_icon</Icon>
              ) : (
                <Icon>expand_less_ios_icon</Icon>
              )}
            </Box>
            <Collapse in={collapse[Number(item.idBeneficiario)]}>
              <Box p="20px">
                {benefitData
                  .filter(i => i.idBeneficiario === item.idBeneficiario)
                  .map(({ perguntas }) =>
                    perguntas.length !== 0 &&
                    perguntas.some(i => i.possui === true) ? (
                      <DataGrid
                        key={item.idBeneficiario}
                        autoHeight
                        columns={columns}
                        rows={rows(perguntas)}
                        hideFooter
                        sx={{
                          '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                            fontSize: 16,
                          },
                          width: '100%',
                        }}
                      />
                    ) : (
                      <Typography key={item.idBeneficiario}>
                        Sem doenças registradas
                      </Typography>
                    ),
                  )}
              </Box>
            </Collapse>
          </Paper>
        ))}
      </Box>
      <Box sx={classes.pageFooter}>
        <Button
          variant="outlined"
          color="secondary"
          endIcon={<Icon>arrow_forward_ios_icon</Icon>}
          onClick={changePreviousDs}
          sx={classes.changeButton}
        >
          Alterar
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<Icon>arrow_forward_ios_icon</Icon>}
          onClick={savePreviousDs}
        >
          Salvar e Continuar
        </Button>
      </Box>
    </Box>
  );
};

export default FormularioIdentificado;
