import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { toast } from 'react-hot-toast';

import { SessionDialog } from 'components';

import { useAuth, usePage } from 'hooks';

import { api } from 'services';

import { token } from 'utils';
import { IForm, IFormContext, IFormDataDraft, IPastForm } from '../interfaces';

const FormContext = createContext<IFormContext>({} as IFormContext);

const FormProvider: React.FC = ({ children }) => {
  const { isAuthenticated, session } = useAuth();

  const { currentPage, setCurrentPage } = usePage();

  const [formData, setFormData] = useState<IForm>({} as IForm);
  const [formDataResponse, setFormDataResponse] = useState<IForm>({} as IForm);
  const [formDataDraft, setFormDataDraft] = useState<IFormDataDraft | null>(
    null,
  );

  const [pastBenefitForm, setPastBenefitForm] = useState<IPastForm>(
    {} as IPastForm,
  );
  const [newChangeDs, setNewChangeDs] = useState<boolean>(false);

  const [showSessionModal, setShowSessionModal] = useState(false);
  const [lastQuestionAnswered, setLastQuestionAnswered] = useState(-1);

  const hasDraft = useMemo(() => !!formDataDraft, [formDataDraft]);
  const dateDraft = useMemo(
    () =>
      formDataDraft ? new Date(formDataDraft.dataPreenchimento) : new Date(),
    [formDataDraft],
  );
  const beneficiariosConfig = useMemo(
    () => formData.beneficiarios ?? [],
    [formData],
  );
  const beneficiariosResponse = useMemo(
    () => formDataResponse?.beneficiarios ?? [],
    [formDataResponse],
  );

  const fetchPreviousForm = (protocoloId: string): void => {
    const headers = { Authorization: `Bearer ${token.getAccessToken()}` };

    api
      .get(
        `formulario/operadora/${session.insuranceHealth}/protocolo/${protocoloId}`,
        {
          headers,
        },
      )
      .then(({ data }) => {
        setPastBenefitForm(JSON.parse(data.formulario));
        setCurrentPage(-3);
      })
      .catch(() => {
        setCurrentPage(4);
      });
  };

  const loadAssets = (cpf: string, protocolo: string): void => {
    const headers = { Authorization: `Bearer ${token.getAccessToken()}` };
    const params = { cpf, protocolo };

    api
      .get('formulario-config', { headers, params })
      .then(({ data }) => {
        setFormData(data);
      })
      .catch(() => {
        toast.error('Não foi possível carregar o formulário');
        setCurrentPage(-2);
      });
  };

  const loadDraft = (protocolo: string): void => {
    const headers = { Authorization: `Bearer ${token.getAccessToken()}` };

    api
      .get(`formulario-rascunho/protocolo/${protocolo}`, { headers })
      .then(({ data }) => {
        if (Object.keys(data).length === 0) {
          setCurrentPage(1);
        } else {
          setFormDataDraft(data);
          setShowSessionModal(true);
        }
      })
      .catch(() => {
        toast.error('Não foi possível carregar a sessão anterior');
      });
  };

  const saveDraft = useCallback(() => {
    const headers = { Authorization: `Bearer ${token.getAccessToken()}` };
    const body = {
      formulario: formDataResponse,
      protocolo: session.protocol,
      sessaoId: session.id,
    };

    api.post('formulario-rascunho', body, { headers });
  }, [api, formDataResponse, session]);

  const handleClose = (): void => {
    setShowSessionModal(false);
    setCurrentPage(1);
  };

  const handleClick = (): void => {
    setShowSessionModal(false);

    if (formDataDraft) {
      setFormDataResponse({
        ...formData,
        ...formDataDraft.formulario,
      });
    }
    setCurrentPage(1);
  };

  const savePreviousDsInfo = (): void => {
    if (pastBenefitForm) {
      const newForm = formDataResponse.beneficiarios.map(newBeneficiaries => {
        const pastFormValidation = pastBenefitForm.beneficiarios.find(
          pastBeneficiaries => pastBeneficiaries.cpf === newBeneficiaries.cpf,
        );
        if (pastFormValidation) {
          return {
            ...newBeneficiaries,
            perguntas: pastFormValidation.perguntas,
          };
        }
        return { ...newBeneficiaries };
      });
      setFormDataResponse(prevState => ({
        ...prevState,
        beneficiarios: newForm,
      }));
    }
  };

  useEffect(() => {
    const { cpf, protocol } = session;

    if (isAuthenticated) {
      loadAssets(cpf, protocol);
      loadDraft(protocol);
    }
  }, [isAuthenticated, session]);

  useEffect(() => {
    setFormDataResponse({ ...formData });
  }, [formData]);

  useEffect(() => {
    if (currentPage > 0) {
      saveDraft();
    }
  }, [formDataResponse]);

  return (
    <FormContext.Provider
      value={{
        beneficiariosConfig,
        beneficiariosResponse,
        fetchPreviousForm,
        formData,
        formDataResponse,
        hasDraft,
        lastQuestionAnswered,
        newChangeDs,
        pastBenefitForm,
        savePreviousDsInfo,
        setFormData,
        setFormDataResponse,
        setLastQuestionAnswered,
        setNewChangeDs,
      }}
    >
      <SessionDialog
        dateLastAccess={dateDraft.toLocaleString()}
        handleClick={handleClick}
        handleClose={handleClose}
        open={showSessionModal}
      />
      {children}
    </FormContext.Provider>
  );
};

export { FormContext, FormProvider };
