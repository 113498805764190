import React from 'react';

import {
  AppBar,
  Button,
  Container,
  Grid,
  Hidden,
  Icon,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  Zoom,
  Box,
} from '@mui/material';

import { useProtocol, useTheme } from 'hooks';

const useStyles = (theme: Theme): any => ({
  root: {
    position: 'fixed',
    top: '50%',
    bottom: '50%',
    right: 0,
    [theme.breakpoints.down(1000)]: {
      right: -20,
    },
    [theme.breakpoints.down(465)]: {
      right: -35,
    },
  },
  rootText: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    fontSize: '0.75rem',
    alignItems: 'center',
  },
  rootIcon: {
    fontSize: '1.5rem',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  img: {
    height: '70px',
    padding: '15px',
  },
  imgSm: {
    height: '50px',
    paddingLeft: '0px',
    padding: '13px',
    gridRow: '1 / 3',
    width: '130px',
  },
  title: {
    flexGrow: 1,
  },
  titleXs: {
    fontSize: '0.7rem',
    alignSelf: 'end',
  },
  protocol: {
    marginLeft: 'auto',
  },
  protocolXs: {
    fontSize: '0.7rem',
    alignSelf: 'start',
  },
  toolbarSm: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
  },
  rootButton: {
    width: '100%',
    height: 'auto',
    padding: '0 7px',
    [theme.breakpoints.down(465)]: {
      padding: '0 15px',
    },
    minHeight: 'auto',
    borderRadius: 24,
    rotate: '270deg',
  },
});

const Header: React.FC = () => {
  const {
    layout: { assets },
    systemTheme,
  } = useTheme();

  const nrProtocolo = useProtocol();
  const classes = useStyles(systemTheme);
  const matchesXs = useMediaQuery('(min-width: 465px)');
  const matchesSm = useMediaQuery('(min-width: 695px)');
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  // Função para tela voltar ao topo
  const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    const anchor = (
      (e.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <>
      <Grid container>
        <AppBar>
          <Container>
            <Toolbar sx={matchesSm ? undefined : classes.toolbarSm}>
              {assets.logo && (
                <img
                  alt="unimed logo"
                  src={assets.logo}
                  style={matchesSm ? classes.img : classes.imgSm}
                />
              )}
              <Hidden>
                <Typography sx={matchesXs ? classes.title : classes.titleXs}>
                  Declaração de Saúde Digital
                </Typography>
              </Hidden>
              <Typography sx={matchesXs ? undefined : classes.protocolXs}>
                {matchesXs ? `Protocolo nº ${nrProtocolo}` : `${nrProtocolo}`}
              </Typography>
            </Toolbar>
          </Container>
        </AppBar>
      </Grid>

      <div id="back-to-top-anchor" />

      <Zoom in={trigger}>
        <Box onClick={handleClick} role="presentation" sx={classes.root}>
          <Tooltip
            title="Voltar ao topo"
            placement="left"
            aria-label="Voltar ao topo"
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              aria-label="scroll back to top"
              sx={classes.rootButton}
            >
              <Typography sx={classes.rootText}>
                Ir ao Topo
                <Icon classes={classes.rootIcon}>
                  keyboard_arrow_right_icon
                </Icon>
              </Typography>
            </Button>
          </Tooltip>
        </Box>
      </Zoom>
    </>
  );
};

export default Header;
