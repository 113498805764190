import React from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';

import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Icon,
  Theme,
} from '@mui/material';

import { IEspecificacao } from 'context';
import { TextInputDisease } from 'components';
import { useTheme } from 'hooks';
import {
  MAX_LENGTH_INPUT_TEXT,
  MAX_LENGTH_INPUT_TEXT_TEMPO,
} from 'pages/Questoes';

type CheckboxPropTypes = {
  disease: IEspecificacao;
  name: string;
};

const useStyles = (theme: Theme): any => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
});

const CheckboxDisease: React.FC<CheckboxPropTypes> = ({ disease, name }) => {
  const { systemTheme } = useTheme();
  const classes = useStyles(systemTheme);
  const { control, watch } = useFormContext();
  const checkboxName = `${name}.portador`;
  const watchCheck = watch(checkboxName);

  return (
    <FormGroup sx={classes.root}>
      <Controller
        control={control}
        name={checkboxName}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={!!field.value}
                onChange={e => field.onChange(e.target.checked)}
                icon={
                  <Icon fontSize="large">check_box_outline_blank_icon</Icon>
                }
                checkedIcon={<Icon fontSize="large">check_box</Icon>}
              />
            }
            label={disease.titulo}
          />
        )}
      />

      {watchCheck && disease.tempo && (
        <TextInputDisease
          name={`${name}.tempo`}
          label={disease.tempo}
          maxLength={MAX_LENGTH_INPUT_TEXT_TEMPO}
        />
      )}

      {watchCheck && disease.tipo && (
        <TextInputDisease
          name={`${name}.tipo`}
          label={disease.tipo}
          maxLength={MAX_LENGTH_INPUT_TEXT}
        />
      )}

      {watchCheck && disease.local && (
        <TextInputDisease
          name={`${name}.local`}
          label={disease.local}
          maxLength={MAX_LENGTH_INPUT_TEXT}
        />
      )}

      {watchCheck && disease.grau && (
        <TextInputDisease
          name={`${name}.grau`}
          label={disease.grau}
          maxLength={MAX_LENGTH_INPUT_TEXT}
        />
      )}
    </FormGroup>
  );
};

export default CheckboxDisease;
