import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { RoutesPreenchimento, RoutesGerenciamento } from 'routes';
import {
  AuthProvider,
  FormProvider,
  PageProvider,
  ThemeProvider,
} from 'context';
import { ErroValidaLink } from 'pages';

const App: React.FC = () => (
  <Router>
    <Switch>
      <Route exact path="/:id">
        <ThemeProvider>
          <PageProvider>
            <AuthProvider>
              <FormProvider>
                <RoutesPreenchimento />
              </FormProvider>
            </AuthProvider>
          </PageProvider>
        </ThemeProvider>
      </Route>
      <Route path="/visualizar-documento/">
        <ThemeProvider>
          <PageProvider>
            <AuthProvider>
              <RoutesGerenciamento />
            </AuthProvider>
          </PageProvider>
        </ThemeProvider>
      </Route>
      <Route path="*">
        <ErroValidaLink />
      </Route>
    </Switch>
  </Router>
);

export default App;
