const ACCESS_TOKEN_KEY = 'decla.auth.access_token';
const REFRESH_TOKEN_KEY = 'decla.auth.refresh_token';

const setAccessToken = (token: string): void => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

const getAccessToken = (): string | null =>
  localStorage.getItem(ACCESS_TOKEN_KEY);

const setRefreshToken = (token: string): void => {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
};

const getRefreshToken = (): string | null =>
  localStorage.getItem(REFRESH_TOKEN_KEY);

export default {
  setAccessToken,
  setRefreshToken,
  getAccessToken,
  getRefreshToken,
};
